import React, { Component } from 'react'
import axios from 'axios'
import RadioSelect from '../RadioSelect'

import style from './style.module.scss'
import logo from '../../images/logo-m4.svg'
import contactFormArrow from '../../images/contact-form-arrow.png'

import questions from './questions'
import FormModal from '../FormModal'
import api from '../../services/api'
let currentQuestions = questions

class ConsultingContactForm extends Component {
  twoOptionsSelectColors = {
    apricot: '#ffbf68',
    fadedOrange: '#f59846',
    whiteTwo: '#ececec',
    whiteThree: '#dcdcdc'
  }

  state = {
    currentQuestion: currentQuestions[0],
    project: 'Consultoria Online',
    projectType: 'Não possui',
    roomsQuantity: '',
    modifyWall: '',
    modifyHidraulicPoint: '',
    modifyLighting: '',
    name: '',
    email: '',
    phone: '',
    city: '',
    description: '',
    isModalOpen: false
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.toggleCurrentQuestionOpacity)
    window.addEventListener('keydown', this.scrollNextQuestion)
  }

  toggleCurrentQuestionOpacity = () => {
    const globalScrollDistanceTop = document.documentElement.scrollTop

    currentQuestions.forEach(question => {
      const offset = question.ref.current.offsetTop - globalScrollDistanceTop

      if (offset >= 50 && offset <= 330) {
        question.questionState = ''
        this.setState({ currentQuestion: question })
      } else if (question.questionState !== style.opacityActive) {
        question.questionState = style.opacityActive
        this.setState(prevState => ({
          currentQuestion: prevState.currentQuestion
        }))
      }
    })
  }

  scrollNextQuestion = event => {
    const { currentQuestion } = this.state
    const nextQuestion = currentQuestions[currentQuestion.number + 1] 
    const isTabOrEnter = event.key === 'Enter' || event.key === 'Tab'
    if (nextQuestion && isTabOrEnter) {
      event.preventDefault()

      // Move cursor to the next input
      const inputs = nextQuestion.ref.current.getElementsByTagName('INPUT')
      if (inputs.length !== 0) inputs[0].focus()

      this.scrollToSection({ currentTarget: nextQuestion.ref.current })
    }
  }

  descriptionText = question => {
    if (question.descriptionText) {
      return (
        <div className={question.descriptionTextClassName}>
          {question.descriptionText}
        </div>
      )
    }
    return ''
  }

  scrollToSection = event => {
    const clickedSection = event.currentTarget
    const previousSectionVisibleAmount = 180
    window.scrollTo(0, clickedSection.offsetTop - previousSectionVisibleAmount)
  }

  goToNextQuestion = question => {
    const nextQuestion = currentQuestions[question.number + 1]
    this.scrollToSection({ currentTarget: nextQuestion.ref.current })
  }

  removeSubsequentQuestions = changedQuestion => {
    return currentQuestions.filter(
      formQuestion => changedQuestion.number >= formQuestion.number
    )
  }

  addSubsequentQuestions = (question, optionSelected) => {
    // In case the user change a previous TwoOptionsSelect
    const twoOptionsSelectQuestions = this.removeSubsequentQuestions(question)

    if (optionSelected === question.optionOne) {
      currentQuestions = twoOptionsSelectQuestions.concat(
        question.leftAwnserQuestions
      )
    }
  }

  saveSubmission = () => {
    const answer = {
      room_quantity: this.state.roomsQuantity,
      modify_wall: this.state.modifyWall,
      modify_plumbing: this.state.modifyHidraulicPoint,
      modify_lighting: this.state.modifyLighting,
      name: this.state.name,
      email: this.state.email,
      whatsapp: this.state.phone,
      city: this.state.city, 
      description: this.state.description,
    }
    api.sheet.save_consult_submission(answer)
  }
  setIsModalOpen = isModalOpen => {
    this.setState({ isModalOpen })
  }
  createQuestion = question => {
    return {
      jsx: <div className={question.className}>{question.jsx}</div>,
      twoOptionsSelect: (
        <div className={question.className}>
          {this.descriptionText(question)}

          <button
            className={`${style.formButton}`}
            type="button"
            onClick={() => {
              this.addSubsequentQuestions(question, question.optionOne)
              this.setState({ [question.formInput]: question.optionOne }, () =>
                this.goToNextQuestion(question)
              )
            }}
          >
            {question.optionOne}
          </button>
        </div>
      ),
      RadioSelect: (
        <div className={question.className}>
          {this.descriptionText(question)}

          <RadioSelect
            name={question.formInput}
            optionsText={question.optionsText}
            optionOne={question.optionOne}
            optionTwo={question.optionTwo}
            optionThree={question.optionThree}
            optionFour={question.optionFour}
            onChange={optionSelected =>
              this.setState({ [question.formInput]: optionSelected }, () =>
                this.goToNextQuestion(question)
              )
            }
          />
        </div>
      ),
      textInput: (
        <div className={question.className}>
          <div>{question.questionText}</div>
          <div>
            <input
              type="text"
              placeholder={question.placeholder}
              onChange={e =>
                this.setState({ [question.formInput]: e.target.value })
              }
            />
          </div>
        </div>
      ),
      submit: (
        <div className={question.className}>
          {question.jsx}

          <FormModal 
            buttonClassName={style.formButton} 
            isModalOpen={this.state.isModalOpen}
            setIsModalOpen={this.setIsModalOpen}
            onOpenModal={() => {this.saveSubmission()}}
          />

        </div>
      )
    }[question.type]
  }

  encode = data => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
  }

  redirectToSuccess = e => {
    e.preventDefault()

    const formData = this.state
    delete formData.currentQuestion

    axios
      .post(
        'https://studio-m4.web.app/leads',
        this.encode({
          formName: 'contato-para-consultoria',
          formType: 'consultoria',
          ...formData
        }),
        {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        }
      )
      .then(() => {
        this.setIsModalOpen(true);

        // window.location = '/contato-consultoria-feito-com-sucesso'
      })
  }

  render() {
    return (
      <form
        name="contato-para-consultoria"
        method="POST"
        onSubmit={this.redirectToSuccess}
        className={`${style.projectContactFormContainer}`}
        data-netlify="true"
        netlify-honeypot="bot-field"
      >
        <a href="/" className={style.logom4}>
          <img src={logo} alt="Logo Studio M4" />
        </a>

        <div>
          <div className={style.formDetails}>
            <div>Tenho interesse em</div>
            <h3>Fazer uma Consultoria</h3>
            <img src={contactFormArrow} alt="form arrow" />
          </div>

          {currentQuestions.map(question => (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div
              key={question.number}
              className={`${question.questionState}`}
              ref={question.ref}
              onClick={this.scrollToSection}
              onKeyDown={this.scrollToSection}
            >
              {this.createQuestion(question)}
            </div>
          ))}

          <div className={style.bottomOffset} />
        </div>

        <span style={{ visibility: 'hidden' }} className={style.hiddenSpan}>
          {/*
            This input will be hidden. It is needed due to the following issue:
            https://www.netlify.com/docs/form-handling/#spam-filtering
          */}
          <input name="bot-field" />
        </span>

        {/*
          These inputs are needed due to a netlify limitation:
          https://www.netlify.com/docs/form-handling/#javascript-rendered-forms
        */}

        <input type="hidden" name="project" />
        <input type="hidden" name="projectType" />
        <input type="hidden" name="roomsQuantity" />
        <input type="hidden" name="name" />
        <input type="hidden" name="email" />
        <input type="hidden" name="phone" />
        <input type="hidden" name="city" />
        <input type="hidden" name="description" />
      </form>
    )
  }
}

export default ConsultingContactForm
