import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ConsultingContactForm from '../components/ConsultingContactForm'

import '../styles/global.scss'

const ConsultingForm = () => (
  <Layout>
    <SEO title="Contato" keywords={[`gatsby`, `application`, `react`]} />
    <ConsultingContactForm />
  </Layout>
)

export default ConsultingForm
