import React from 'react'
import style from './style.module.scss'

const personalInfoQuestions = [
  {
    number: 1,
    type: 'RadioSelect',
    formInput: 'roomsQuantity',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.radioSelect} ${style.clickActive}`,
    optionsText: 'Escolha a quantidade de ambientes',
    optionOne: '1 a 3 ambientes',
    optionTwo: '4 a 6 ambientes',
    optionThree: '7 a 10 ambientes',
    optionFour: '10 ou mais ambientes',
    descriptionTextClassName: style.descriptionText,
  },
  {
    number: 2,
    type: 'RadioSelect',
    formInput: 'modifyWall',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.radioSelect} ${style.clickActive}`,
    optionsText: 'Pretende modificar alguma parede?',
    optionOne: 'Sim',
    optionTwo: 'Não',
    // descriptionTextClassName: style.descriptionText,
  },
  {
    number: 3,
    type: 'RadioSelect',
    formInput: 'modifyHidraulicPoint',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.radioSelect} ${style.clickActive}`,
    optionsText: 'Pretende modificar algum ponto hidráulico?',
    optionOne: 'Sim',
    optionTwo: 'Não',
    descriptionTextClassName: style.descriptionText,
  },
  {
    number: 4,
    type: 'RadioSelect',
    formInput: 'modifyLighting',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.radioSelect} ${style.clickActive}`,
    optionsText: 'Pretende modificar a iluminação e/ou pontos elétricos?',
    optionOne: 'Sim',
    optionTwo: 'Não',
    descriptionTextClassName: style.descriptionText,
  },
  {
    number: 5,
    type: 'textInput',
    formInput: 'name',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual seu nome?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 6,
    type: 'textInput',
    formInput: 'email',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual o seu email?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 7,
    type: 'textInput',
    formInput: 'phone',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual seu Whatsapp?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 8,
    type: 'textInput',
    formInput: 'city',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual é a sua cidade/estado?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 9,
    type: 'textInput',
    formInput: 'description',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Forneça uma breve descrição dos ambientes',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 10,
    type: 'submit',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.submitSection} ${style.clickActive}`,
    jsx: (
      <span>
        {/* <div>
          <strong>OBS:</strong>
          <p>
            As consultorias acontecem sempre na segunda semana de cada mês. Ao
            enviar este formulário, nossa equipe entrará em contato no prazo de
            24h úteis para marcar seu horário.
          </p>
        </div> */}
      </span>
    )
  }
]

export default [
  {
    number: 0,
    type: 'twoOptionsSelect',
    formInput: 'project',
    ref: React.createRef(),
    questionState: '',
    className: style.twoOptionsSelect,
    questionText: '',
    optionOne: 'Ok, entendi!',
    descriptionTextClassName: style.descriptionText,
    descriptionText: (
      <span>
        {/* <p>
          <strong>R$400,00</strong>
        </p> */}

        <p style={{textAlign: "justify",}}>
        Nossa equipe se reúne uma vez por mês, por um dia inteiro, para se
         dedicar exclusivamente às consultorias. Por isso chamamos de Temporada
          de Consultoria. Abrimos vagas limitadas por temporada, de forma que
           nossa equipe consiga atender plenamente cada cliente. Preencha os
            campos abaixo e nossa equipe entra em contato para mais informações:
        </p>
      </span>
    ),
    leftAwnserQuestions: personalInfoQuestions,
    rightAwnserQuestions: personalInfoQuestions
  }
]
